import {
  type AssistantMessageFE,
  type AssistantSizeStateFE,
  type AssistantTeaserStateFE,
  type AssistantVisitedPagesStateFE
} from 'ecosystem'
import { windowLocalStorageSsr, windowSessionStorageSsr } from './windowStoragesSsr'

const sessionStorage = windowSessionStorageSsr
const localStorage = windowLocalStorageSsr

class AssistantStorage {
  private static instance: AssistantStorage
  private storagePrefix = 'assistant'

  private constructor() {
    void 0
  }

  public static getInstance(): AssistantStorage {
    if (!AssistantStorage.instance) {
      AssistantStorage.instance = new AssistantStorage()
    }

    return AssistantStorage.instance
  }

  public getHistory(): AssistantMessageFE[] | null {
    try {
      const items = JSON.parse(sessionStorage.getItem(`${this.storagePrefix}-history`) || '')

      return Array.isArray(items) ? items : null
    } catch (err) {
      return null
    }
  }

  public setHistory(newHistory: AssistantMessageFE[] | null) {
    try {
      sessionStorage.setItem(`${this.storagePrefix}-history`, JSON.stringify(newHistory))
    } catch (err) {
      void 0
    }
  }

  public removeHistory() {
    sessionStorage.removeItem(`${this.storagePrefix}-history`)
  }

  public getVisitedPages(): AssistantVisitedPagesStateFE {
    const defaultVisitedPages = {
      lastCategoryPage: '',
      lastBrandPage: '',
      lastTagPage: '',
      lastProductPage: ''
    }

    try {
      const visitedPagesState = JSON.parse(
        sessionStorage.getItem(`${this.storagePrefix}-visitedPages`) || ''
      )

      return visitedPagesState ?? defaultVisitedPages
    } catch (err) {
      return defaultVisitedPages
    }
  }

  public setVisitedPages(newVisitedPages: AssistantVisitedPagesStateFE) {
    try {
      sessionStorage.setItem(`${this.storagePrefix}-visitedPages`, JSON.stringify(newVisitedPages))
    } catch (err) {
      void 0
    }
  }

  public removeVisitedPages() {
    sessionStorage.removeItem(`${this.storagePrefix}-visitedPages`)
  }

  public getThreadId(): string | null {
    return sessionStorage.getItem(`${this.storagePrefix}-threadId`)
  }

  public setThreadId(threadId: string) {
    sessionStorage.setItem(`${this.storagePrefix}-threadId`, threadId)
  }

  public removeThreadId() {
    sessionStorage.removeItem(`${this.storagePrefix}-threadId`)
  }

  public getSessionUserId(): string | null {
    return sessionStorage.getItem(`${this.storagePrefix}-sessionUserId`)
  }

  public setSessionUserId(sessionUserId: string) {
    sessionStorage.setItem(`${this.storagePrefix}-sessionUserId`, sessionUserId)
  }

  public removeSessionUserId() {
    sessionStorage.removeItem(`${this.storagePrefix}-sessionUserId`)
  }

  public getSizeState(): string | null {
    return localStorage.getItem(`${this.storagePrefix}-sizeState`)
  }

  public setSizeState(size: AssistantSizeStateFE) {
    localStorage.setItem(`${this.storagePrefix}-sizeState`, size)
  }

  public setTeaserState(teaserState: AssistantTeaserStateFE) {
    localStorage.setItem(`${this.storagePrefix}-teaserState`, teaserState)
  }

  public getTeaserState(): AssistantTeaserStateFE | null {
    return localStorage.getItem(
      `${this.storagePrefix}-teaserState`
    ) as AssistantTeaserStateFE | null
  }
}

export const assistantStorage = AssistantStorage.getInstance()
