const mockStorage = {
  getItem(_key: string): null | string {
    return null
  },
  setItem(_key: string, _value: string): void {
    return void 0
  },
  removeItem(_key: string): void {
    return void 0
  }
}
// Don't raise errors during ssr
export const windowSessionStorageSsr =
  typeof window !== 'undefined' ? window.sessionStorage : mockStorage
export const windowLocalStorageSsr =
  typeof window !== 'undefined' ? window.localStorage : mockStorage
