import deepEqual from 'fast-deep-equal'

/**
 * Finds an item in an array based on a comparison function and updates it using a callback.
 *
 * @example
 * ```typescript
 * const users = [{ id: 1, name: "Alice" }, { id: 2, name: "Bob" }];
 *
 * arrayFindAndUpdateItem({
 *   arr: users,
 *   compare: (user) => user.id === 1,
 *   cb: (user) => { user.name = "Alice Updated"; }
 * });
 *
 * console.log(users); // [{ id: 1, name: "Alice Updated" }, { id: 2, name: "Bob" }]
 * ```
 */
export const arrayFindAndUpdateItem = <Item = any>({
  arr,
  compare,
  cb
}: {
  arr: Item[]
  compare: (item: Item) => boolean
  cb: (foundItem: Item) => void
}) => {
  const foundIndex = arr.findIndex((item) => compare(item))

  if (foundIndex === -1) {
    return arr
  }

  cb(arr[foundIndex])

  return arr
}

/**
 * Finds an item in an array based on a comparison function and replaces it with a new item.
 *
 * @param arr - The array to search within.
 * @param newItem - The new item to replace the existing one.
 * @param compare - Optional comparison function to find the matching item.
 * If not provided, deep equality comparison is used.
 * @returns The updated array with the replaced item.
 *
 * @example
 * ```ts
 * const users = [{ id: 1, name: "Alice" }, { id: 2, name: "Bob" }];
 *
 * arrayFindAndReplaceItem({
 *   arr: users,
 *   newItem: { id: 1, name: "Alice Updated" },
 *   compare: (user) => user.id === 1
 * });
 *
 * console.log(users); // [{ id: 1, name: "Alice Updated" }, { id: 2, name: "Bob" }]
 * ```
 */
export const arrayFindAndReplaceItem = <Item = any>({
  arr,
  newItem,
  compare
}: {
  arr: Item[]
  newItem: Item
  compare?: (item: Item) => boolean
}) => {
  const foundIndex = arr.findIndex((item) => (compare ? compare(item) : deepEqual(item, newItem)))

  if (foundIndex === -1) {
    return arr
  }

  return arr.splice(foundIndex, 1, newItem)
}

/**
 * Finds an item in an array based on a comparison function and delete it.
 *
 * @param arr - The array to search within.
 * @param compare - Comparison function to find the matching item.

 * @returns The updated array with the deleted item.
 *
 * @example
 * ```ts
 * const users = [{ id: 1, name: "Alice" }, { id: 2, name: "Bob" }];
 *
 * arrayFindAndReplaceItem({
 *   arr: users,
 *   compare: (item) => item.id === 1
 * });
 *
 * console.log(users); // [{ id: 2, name: "Bob" }]
 * ```
 */
export const arrayFindAndDeleteItem = <Item = any>({
  arr,
  compare
}: {
  arr: Item[]
  compare: (item: Item) => boolean
}) => {
  const foundIndex = arr.findIndex((item) => compare(item))

  if (foundIndex === -1) {
    return arr
  }

  return arr.splice(foundIndex, 1)
}

/**
 * Adds an element to the array if it does not already exist, or updates an existing one using a comparison function.
 *
 * @param arr - The array to update.
 * @param newItem - The new item to add or replace.
 * @param compare - Optional comparison function to check for an existing match.
 * If not provided, deep equality comparison is used.
 *
 * @example
 * ```ts
 * const users = [{ id: 1, name: "Alice" }];
 *
 * arrayPushOrUpdateItem({
 *   arr: users,
 *   newItem: { id: 1, name: "Alice Updated" },
 *   compare: (user) => user.id === 1
 * });
 *
 * console.log(users); // [{ id: 1, name: "Alice Updated" }]
 * ```
 */
export const arrayPushOrUpdateItem = <Item = any>({
  arr,
  newItem,
  compare
}: {
  arr: Item[]
  newItem: Item
  compare?: (item: Item) => boolean
}) => {
  if (!inArray({ arr, item: newItem, compare })) {
    arr.push(newItem)
  } else {
    arrayFindAndReplaceItem({ arr, newItem, compare })
  }
}

/**
 * Checks if an item exists in an array using a comparison function or deep equality.
 *
 * @param arr - The array to search within.
 * @param item - The item to check for existence.
 * @param compare - Optional comparison function to determine if an item exists.
 * If not provided, deep equality comparison is used.
 * @returns `true` if the item exists in the array, otherwise `false`.
 *
 * @example
 * ```typescript
 * const users = [{ id: 1, name: "Alice" }];
 *
 * const exists = inArray({
 *   arr: users,
 *   item: { id: 1, name: "Alice" },
 *   compare: (user) => user.id === 1
 * });
 *
 * console.log(exists); // true
 * ```
 */
export const inArray = <Item = any>({
  arr,
  item,
  compare
}: {
  arr: Item[]
  item: Item
  compare?: (item: Item) => boolean
}) => {
  return arr.some((arrItem) => (compare ? compare(arrItem) : deepEqual(item, arrItem)))
}
