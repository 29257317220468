import { Box } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { type ComponentProps } from 'react'

const ChakraBox = motion(Box)

const animationVariants = {
  hidden: { scale: 0, rotate: -180, opacity: 0 },
  visible: { scale: 1, rotate: 0, opacity: 1 },
  exit: { scale: 0, rotate: 180, opacity: 0 }
}

export type CounterBadgeProps = ComponentProps<typeof ChakraBox> & {
  value: number
  size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
}

export const CounterBadge = ({ value, size = 'xs', ...props }: CounterBadgeProps) => {
  return (
    <AnimatePresence initial={false} mode="popLayout">
      <ChakraBox
        key={value}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={animationVariants}
        fontSize={size}
        bgColor="primary"
        color="white"
        p="0.3em"
        borderRadius="1em"
        minW="1em"
        boxSizing="content-box"
        lineHeight="1"
        textAlign="center"
        boxShadow="md"
        transition={{ type: 'spring', bounce: 0.35, duration: 0.8 }}
        {...props}>
        {value}
      </ChakraBox>
    </AnimatePresence>
  )
}
