import { type AppConfigBase, type PathObj, type PathType } from 'ecosystem'

/**
 * A utility function that extends the behavior of `URLSearchParams` to handle a variety of input types,
 * including `Record<string, null | string | number | (string | null | number)[]>`
 */
export const createUrlSearchParams = (
  init?:
    | string[][]
    | Record<string, null | string | number | (string | null | number)[]>
    | string
    | URLSearchParams
) => {
  if (typeof init === 'object' && !Array.isArray(init) && !(init instanceof URLSearchParams)) {
    const result = new URLSearchParams()

    Object.entries(init).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          // format of array should be ?paramName[]=value1&paramName[]=value2
          result.append(`${key}[]`, String(val))
        })
      } else if (typeof value !== 'undefined') {
        result.append(key, String(value))
      }
    })

    return result
  }

  return new URLSearchParams(init)
}

/**
 * Constructs a full URL by appending query parameters to a base URL.
 *
 * @param url - The base URL to which the query parameters will be appended.
 * @param queryParams - Optional query parameters that can be in various forms
 *  such as an object, array, string, or URLSearchParams. This parameter will be passed to `createUrlSearchParams` to
 *  convert it into a URL-friendly query string.
 *
 * @returns string - The resulting URL with the query parameters appended. If no query parameters are provided,
 *  the base URL is returned as is.
 *
 * @example
 * ```ts
 *  createUrl('https://example.com', { page: 1, size: 10 });
 *  // Returns: 'https://example.com?page=1&size=10'
 * ```
 */
export const createUrl = (
  url: string,
  queryParams?: Parameters<typeof createUrlSearchParams>[0]
): string => {
  const params = createUrlSearchParams(queryParams).toString()

  return `${url}${params ? `?${params}` : ''}`
}

export const constructURLWithParams = (
  baseUrl: string,
  queryParams: Record<string, string | undefined | null>
) => {
  const params = new URLSearchParams()

  for (const [key, value] of Object.entries(queryParams)) {
    if (value && value.trim() !== '') {
      params.append(key, value)
    }
  }

  return params.toString() ? `${baseUrl}?${params.toString()}` : baseUrl
}

export const isExternalLink = (url: string): boolean => {
  const externalPattern =
    /^(?:https?:\/\/|mailto:|tel:|ftp:\/\/|ftps:\/\/|file:\/\/|news:\/\/|gopher:\/\/|ws:\/\/|wss:\/\/|www\.)/i
  return externalPattern.test(url)
}

export const normalizeHref = (url: string, external: boolean | undefined) => {
  if (external && !/^https?:\/\//i.test(url)) {
    return `https://${url}`
  }
  return url
}

export const hrefFromPathObj = (
  obj: PathObj,
  pathMap: Record<Exclude<PathType, 'custom'>, string>
) => {
  if (obj.type === 'custom') {
    return obj.slug
  }
  return `${pathMap[obj.type]}/${obj.slug}`
}

export const hrefFromAppPaths = (obj: PathObj, appPaths: AppConfigBase['paths'] | undefined) => {
  return hrefFromPathObj(obj, {
    category: appPaths?.categoriesUrlPath || 'example-category-path',
    brand: appPaths?.brandsUrlPath || 'example-brand-path',
    tag: appPaths?.tagsUrlPath || 'example-tag-path',
    blog: appPaths?.blogsUrlPath || 'example-blog-path'
  })
}

/**
 * Resolves a relative or absolute subpath against a base URL or string and returns the resulting URL as a string.
 *
 * @param base - The base URL or string to resolve against. This should be a valid URL or URL-like string.
 * @param subPath - The subpath or URL to resolve. It can be a relative path or a full URL.
 * @returns A string representing the resolved URL.
 *
 * @example
 * // Resolving a relative path against a base URL
 * const resolved = resolveUrlPath('https://example.com', '/terms');
 * console.log(resolved); // Output: 'https://example.com/terms'
 *
 * @example
 * // Resolving a URL against another base URL
 * const resolved = resolveUrlPath('https://example.com', 'https://another.com/terms');
 * console.log(resolved); // Output: 'https://another.com/terms'
 */
export const resolveUrlPath = (base: string | URL, subPath: string | URL): string => {
  return new URL(subPath, base).toString()
}
