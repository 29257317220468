'use client'
import { type FC, useMemo } from 'react'
import sanitizeHtml, { defaults, type Transformer } from 'sanitize-html'

interface ParsedHtmlProps {
  html: string
}

/** @see https://www.npmjs.com/package/sanitize-html */
const htmlSanitizerOptions = {
  allowedTags: defaults.allowedTags.concat(['a', 'img', 'iframe', 'video', 'source']),
  allowedClasses: {
    '*': ['ql-*']
  },
  allowedAttributes: {
    ...defaults.allowedAttributes,
    '*': ['style', 'id'],
    a: ['href', 'rel', 'target'],
    iframe: ['src', 'style', 'height', 'width', 'title', 'loading', 'data-*'],
    video: [
      'style',
      'controls',
      'autoplay',
      'muted',
      'playsinline',
      'loop',
      'poster',
      'width',
      'height',
      'preload'
    ],
    source: ['src', 'type', 'media']
  },
  allowedIframeHostnames: ['widget.reco.se', 'www.youtube.com'],
  transformTags: {
    // add lazy loading for the iframe by default
    iframe: ((tagName, attrs) => {
      return {
        tagName,
        attribs: {
          ...attrs,
          loading: attrs.loading ?? 'lazy'
        }
      }
    }) as Transformer
  }
}

/** Sanitize an html string and return a React component */
const ParsedHtml: FC<ParsedHtmlProps> = ({ html }) => {
  const parsedHtml = useMemo(() => sanitizeHtml(html, htmlSanitizerOptions), [html])

  return <span dangerouslySetInnerHTML={{ __html: parsedHtml }} />
}

export default ParsedHtml
