import { type ReactNode, type SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { isUndefined } from 'shared-utils'
import { MdImageNotSupported } from 'react-icons/md'
import { Box } from '@chakra-ui/react'
import { GrandImage, type GrandImageProps } from './GrandImage'

type GrandImageWithFallbackProps = GrandImageProps & {
  fallback?: ReactNode
}

export const GrandImageWithFallback = ({
  fallback,
  onError,
  src,
  ...props
}: GrandImageWithFallbackProps) => {
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    setIsError(false)
  }, [src])

  const handleOnError = useCallback(
    (e: SyntheticEvent<HTMLImageElement>) => {
      onError?.(e)
      setIsError(true)
    },
    [onError]
  )

  if (isError) {
    return !isUndefined(fallback) ? (
      <>{fallback}</>
    ) : (
      <Box color="gray.200" alignSelf="center">
        <MdImageNotSupported />
      </Box>
    )
  }

  return <GrandImage onError={handleOnError} src={src} {...props} />
}
