import { useEffect, useRef, useState } from 'react'
import { useRefUpdated } from './useRefUpdated'

/** Doesn't work if element is not rendered at the moment of calling this function.
 * `import { useInView } from 'react-intersection-observer'` could be used instead
 */
function useOnScreen(ref: any, cb?: (isOnScreen: boolean) => void) {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const observer = useRef<IntersectionObserver | null>(null)
  const cbRef = useRefUpdated(cb)

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting)
      cbRef.current?.(entry.isIntersecting)
    })
    if (ref?.current) {
      observer.current.observe(ref.current)
    }
    return () => {
      observer.current?.disconnect()
    }
  }, [cbRef, observer, ref])

  return isIntersecting
}

export default useOnScreen
