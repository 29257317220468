import { type CartItem, type ICartItemFlat } from 'ecosystem'
import { parseIntSafe } from './numbers'

export const flatCartItems = (cartItems: CartItem[]) => {
  return cartItems
    .map((cartItem): ICartItemFlat[] => {
      if (cartItem.type === 'SINGLE') {
        return [
          {
            cartItemId: cartItem.id,
            qty: cartItem.qty,
            product: cartItem.meta.singleType.product,
            subItems: cartItem.meta.singleType.subItems
          }
        ]
      }

      return cartItem.meta.groupType.items.map((detail) => ({
        cartItemId: cartItem.id,
        qty: (detail.originalQty ?? 1) * (parseIntSafe(cartItem.qty) ?? 1),
        product: detail.product,
        subItems: detail.subItems
      }))
    })
    .flat(1)
}
