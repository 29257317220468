import { type Language } from 'ecosystem'

const dictLocales: Record<Language, string> = {
  en: 'en-GB',
  sv: 'sv-SE',
  da: 'da-DK',
  no: 'nb-NO',
  de: 'de-DE',
  fi: 'fi-FI'
}
export const getFullLocale = (
  locale: Language,
  dictLocalesOverride?: Partial<Record<Language, string>>
) => {
  const dict = {
    ...dictLocales,
    ...dictLocalesOverride
  }
  const foundLocale = dict[locale]

  return foundLocale ?? locale
}
