import { withNaming } from '@bem-react/classname'
import type { Option, UIVariantOption } from 'ecosystem'

export interface findNodeResult {
  currentNode: any
  parentNodes: any[]
}

export function findNode(
  slug: string,
  childProp: string,
  currentNode: any
): findNodeResult | false {
  let i, currentChild, result

  if (slug === currentNode.slug) {
    return { currentNode, parentNodes: [] }
  }
  if (currentNode[childProp]) {
    for (i = 0; i < currentNode[childProp].length; i += 1) {
      currentChild = currentNode[childProp][i]
      // Search in the current child
      result = findNode(slug, childProp, currentChild)
      // Return the result if the node has been found
      if (result !== false) {
        return {
          ...result,
          parentNodes: [currentNode, ...result.parentNodes]
        }
      }
    }
  }
  return false
}

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

/** default localization for all pages */
export const defaultLocaleLib = ['common', 'languages', 'footer', 'contact-us']

export interface StaticPath {
  params: any
}

export type StaticPathWithLocale = StaticPath & {
  locale?: string
}

export const localizePath = (
  path: StaticPath,
  locales: any[],
  generateAllLocales = false
): StaticPathWithLocale[] => {
  if (generateAllLocales && locales.length > 1) {
    return locales.map((locale: string) => ({ ...path, locale }))
  }
  return [{ ...path }]
}

export const isEmpty = (value: any) => {
  return (
    // eslint-disable-next-line eqeqeq -- From standard.js: Always use === - but obj == null is allowed to check null || undefined
    value == null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export function isEqual(obj1: any, obj2: any) {
  function getType(obj: any) {
    return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase()
  }

  function areArraysEqual() {
    // Check length
    if (obj1.length !== obj2.length) return false

    // Check each item in the array
    for (let i = 0; i < obj1.length; i++) {
      if (!isEqual(obj1[i], obj2[i])) return false
    }

    // If no errors, return true
    return true
  }

  function areObjectsEqual() {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false

    // Check each item in the object
    for (const key in obj1) {
      if (Object.prototype.hasOwnProperty.call(obj1, key)) {
        if (!isEqual(obj1[key], obj2[key])) return false
      }
    }

    // If no errors, return true
    return true
  }

  function areFunctionsEqual() {
    return obj1.toString() === obj2.toString()
  }

  function arePrimativesEqual() {
    return obj1 === obj2
  }

  // Get the object type
  const type = getType(obj1)

  // If the two items are not the same type, return false
  if (type !== getType(obj2)) return false

  // Compare based on type
  if (type === 'array') return areArraysEqual()
  if (type === 'object') return areObjectsEqual()
  if (type === 'function') return areFunctionsEqual()
  return arePrimativesEqual()
}

export const blogPostSlug = (slug = '') => {
  if (slug === '') {
    return slug
  }

  return slug
    .replace(/\s*[-:+]\s*/g, '-')
    .replace(/\s/g, '-')
    .replace(/[?!.,]/g, '')
    .replace(/[ÅåÄä]/g, 'a')
    .replace(/[Öö]/g, 'o')
    .toLowerCase()
}

export function truncateString(str: string, limit: number) {
  if (str.length > limit) {
    return `${str.slice(0, limit)}...`
  }
  return str
}

export const createCn = withNaming({ e: '__', m: '_', v: '-' })

export const scrollToTop = () => {
  if (typeof window === 'undefined') return

  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export const groupByFirstLetter = (arr: any[]) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÅ'
  const data = arr.reduce((r: any, e) => {
    // get first letter of name of current element
    // converts to lowercase to avoid case bugs
    const group: any = e?.name?.[0]?.toLowerCase?.() || ''
    // if there is no property in accumulator with this letter create it
    if (!r[group]) r[group] = { group, children: [e] }
    // if there is push current element to children array for that letter
    else r[group].children.push(e)
    // return accumulator
    return r
  }, {})

  // since data at this point is an object, to get array of values
  // we use Object.values method
  // we sort the values in alphabetical order
  // we return group value to uppercase
  const groups = Object.values(data)
    .map((i: any) => ({
      ...i,
      group: i.group.toUpperCase()
    }))
    .sort((a: any, b: any) => a.group.localeCompare(b.group))

  // returns a complete alphabet array
  return Array.from(alphabet).map((letter) => {
    const group = groups.find((e) => e.group === letter)

    if (group) {
      return group
    }
    return {
      group: letter,
      children: null
    }
  })
}

export function getOccurrence(array: string[], value: string) {
  let count = 0
  array.forEach((v) => v === value && count++)
  return count
}

export const sumPropValues = function (arr: any[], prop: string) {
  let total = 0
  for (let i = 0, _len = arr.length; i < _len; i++) {
    const value = arr[i][prop]
    if (typeof value === 'string') {
      total += parseFloat(arr[i][prop])
    } else if (typeof value === 'number') {
      total += arr[i][prop] as number
    }
  }
  return total
}

export const sumElements = function (arr: number[]) {
  return arr.filter(notEmpty).reduce((partialSum: number, a: number) => partialSum + a, 0)
}

export const debounce = function (limit: any, callback: () => void) {
  let timeoutId: any
  return (...args: [...any]) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(callback, limit, args)
  }
}

export function assertIsNode(e: EventTarget | null): asserts e is Node {
  if (!e || !('nodeType' in e)) {
    throw new Error(`Node expected`)
  }
}

export const stdCanonicalFactory = (path: Option<string>, server = '') => {
  if (!path) return
  return (server + path).split('?')[0]
}

export const isNullable = (value: any): value is undefined | null =>
  value === null || typeof value === 'undefined'

export const isUndefined = (value: any): value is undefined => typeof value === 'undefined'

export const returnUndefinedIfNullish = <T>(value: T | null | undefined): T | undefined => {
  return value ?? undefined
}

export const sumArrayOfNumbers = (arr: number[]) => arr.reduce((partialSum, a) => partialSum + a, 0)

export const paramsFromObj = (queryFilter: Record<string, any>) => {
  const urlParams = new URLSearchParams()

  for (const [key, value] of Object.entries(queryFilter)) {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        urlParams.append(key, val)
      })
    } else {
      urlParams.append(key, String(value))
    }
  }

  return urlParams.toString()
}

export const optionHelpers = (option: UIVariantOption) => {
  return {
    groupFinder: (e: UIVariantOption) => e.groupId === option.groupId,
    optionFinder: (e: UIVariantOption) => e.groupId === option.groupId && e.value === option.value,
    itemFilter: (e: UIVariantOption) => e.value !== option.value,
    groupFilter: (e: UIVariantOption) => e.groupId !== option.groupId
  }
}
