import { BsCloudSlash } from 'react-icons/bs'
import { Box, Center, type CenterProps, Text } from '@chakra-ui/react'

export const NoData = (props: CenterProps) => (
  <Center h="100%" color="gray.400" gap={2} fontSize="md" flexDirection="column" {...props}>
    <Box fontSize="300%">
      <BsCloudSlash />
    </Box>
    {'children' in props ? props.children : <Text>No data</Text>}
  </Center>
)
