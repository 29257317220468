'use client'

import { type ReactNode, useMemo, useEffect, useCallback, useState } from 'react'
import type { CenterProps } from '@chakra-ui/react'
import { Spinner, Center } from '@chakra-ui/react'
import { LuUser } from 'react-icons/lu'
import { isUndefined } from 'shared-utils'
import { type GrandImageProps, GrandImage } from './GrandImage'

type IGrandAvatarSize = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export type GrandAvatarProps = CenterProps & {
  imageProps?: Partial<GrandImageProps>
  fallback?: ReactNode
  maxLoadAttempts?: number
  isLoading?: boolean
  size?: IGrandAvatarSize
  src?: GrandImageProps['src']
}

const stylesBySize: Record<IGrandAvatarSize, { width: string; image: { sizes: string } }> = {
  '2xs': {
    width: '1rem',
    image: {
      sizes: '20px'
    }
  },
  xs: {
    width: '1.5rem',
    image: {
      sizes: '30px'
    }
  },
  sm: {
    width: '2rem',
    image: {
      sizes: '40px'
    }
  },
  md: {
    width: '3rem',
    image: {
      sizes: '60px'
    }
  },
  lg: {
    width: '4rem',
    image: {
      sizes: '80px'
    }
  },
  xl: {
    width: '6rem',
    image: {
      sizes: '100px'
    }
  },
  '2xl': {
    width: '8rem',
    image: {
      sizes: '140px'
    }
  }
}

export const GrandAvatar = ({
  imageProps: { alt, ...imageProps } = {},
  fallback,
  children,
  maxLoadAttempts = 0,
  isLoading: _isLoading,
  size = 'md',
  sx,
  src,
  ...props
}: GrandAvatarProps) => {
  const [isError, setIsError] = useState(false)
  const [currentAttempt, setCurrentAttempt] = useState(0)
  const [isAttempting, setIsAttempting] = useState(false)

  const isLoading = _isLoading || isAttempting

  const styles = useMemo(() => stylesBySize[size], [size])

  useEffect(() => {
    setCurrentAttempt(0)
    setIsError(false)
    setIsAttempting(false)
  }, [src])

  const onError = useCallback(() => {
    if (maxLoadAttempts < currentAttempt) {
      setIsAttempting(true)

      setTimeout(() => {
        setCurrentAttempt((prev) => prev + 1)
      }, 1000)

      return
    }

    setIsError(true)
    setIsAttempting(false)
  }, [currentAttempt, maxLoadAttempts])

  const onLoad = useCallback(() => {
    setIsAttempting(false)
  }, [])

  const renderFallback = () => (isUndefined(fallback) ? <LuUser /> : <>{fallback}</>)

  return (
    <Center
      bg="gray.300"
      color="#fff"
      overflow="hidden"
      position="relative"
      rounded="50%"
      sx={{
        '--avatar-size': styles.width,
        h: 'var(--avatar-size)',
        w: 'var(--avatar-size)',
        ...sx
      }}
      {...props}>
      <>
        {!!src && !isError ? (
          <GrandImage
            alt={alt || ''}
            fill
            key={currentAttempt}
            onError={onError}
            onLoad={onLoad}
            src={src}
            objectFit="cover"
            sizes={styles?.image.sizes}
            {...imageProps}
          />
        ) : (
          renderFallback()
        )}

        {isLoading && (
          <Center
            backdropFilter="blur(4px)"
            bg="rgba(0,0,0,0.1)"
            h="100%"
            position="absolute"
            w="100%"
            zIndex={2}>
            <Spinner />
          </Center>
        )}

        {children}
      </>
    </Center>
  )
}
