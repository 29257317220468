import type { AssistantSizeStateFE } from 'ecosystem'
import { windowLocalStorageSsr } from './windowStoragesSsr'

// const sessionStorage = windowSessionStorageSsr
const localStorage = windowLocalStorageSsr

class AihubStorage {
  private static instance: AihubStorage
  private storagePrefix = 'aihub'

  private constructor() {
    void 0
  }

  public static getInstance(): AihubStorage {
    if (!AihubStorage.instance) {
      AihubStorage.instance = new AihubStorage()
    }

    return AihubStorage.instance
  }

  public getSizeState(): string | null {
    return localStorage.getItem(`${this.storagePrefix}-sizeState`)
  }

  public setSizeState(size: AssistantSizeStateFE) {
    localStorage.setItem(`${this.storagePrefix}-sizeState`, size)
  }

  public getAnalyticUserId(): string | null {
    return localStorage.getItem(`${this.storagePrefix}-analyticUserId`)
  }

  public setAnalyticUserId(userSessionId: string) {
    localStorage.setItem(`${this.storagePrefix}-analyticUserId`, userSessionId)
  }

  public removeAnalyticUserId() {
    localStorage.removeItem(`${this.storagePrefix}-analyticUserId`)
  }
}

export const aihubStorage = AihubStorage.getInstance()
