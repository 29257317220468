type FileSizeUnit = 'b' | 'kb' | 'mb' | 'gb'

/**
 * Converts a given file size to the specified unit (B, KB, MB, GB).
 *
 * The `fileSizeValue` function takes a file size in bytes and a unit ('b', 'kb', 'mb', 'gb'),
 * and converts the size to the specified unit.
 *
 * @param size - The size of the file in bytes.
 * @param unit - The unit to convert the file size to. Default is 'b' (bytes).
 * @returns The file size converted to the specified unit.
 *
 * @example
 * // Returns 3000
 * fileSizeValue(3, 'kb');
 *
 * @example
 * // Returns 1000000
 * fileSizeValue(1, 'mb');
 */
export const fileSizeValue = (size: number, unit: FileSizeUnit = 'b'): number => {
  const multiplier = 1000

  const dict: Record<FileSizeUnit, number> = {
    b: size,
    kb: size * multiplier,
    mb: size * multiplier * multiplier,
    gb: size * multiplier * multiplier * multiplier
  }

  return dict[unit]
}

const oneKb = fileSizeValue(1, 'kb')
const oneMb = fileSizeValue(1, 'mb')
const oneGb = fileSizeValue(1, 'gb')

/**
 * Formats a given file size in bytes into a human-readable string with an appropriate unit (B, KB, MB, GB).
 *
 * The `prettifyFileSize` function takes a file size in bytes and converts it into a more readable format by
 * choosing the appropriate unit (Bytes, Kilobytes, Megabytes, or Gigabytes) based on the size. The result
 * is returned as a string with one decimal place precision, unless the fractional part is zero, in which case
 * it is omitted.
 *
 * @param size - The size of the file in bytes.
 * @returns The formatted file size as a string, including the appropriate unit.
 *
 * @example
 * // Returns "500 B"
 * prettifyFileSize(500);
 *
 * @example
 * // Returns "1.5 KB"
 * prettifyFileSize(1536);
 *
 * @example
 * // Returns "2 MB"
 * prettifyFileSize(2097152);
 */
export const prettifyFileSize = (size: number): string => {
  let divider = 1
  let unit = 'B'

  if (size >= oneKb && size < oneMb) {
    divider = oneKb
    unit = 'KB'
  } else if (size >= oneMb && size < oneGb) {
    divider = oneMb
    unit = 'MB'
  } else if (size >= oneGb) {
    divider = oneGb
    unit = 'Gb'
  }

  const res = size / divider
  const resStr = res.toFixed(1)

  if (resStr.split('.')[1] === '0') {
    return `${res.toFixed(0)} ${unit}`
  }

  return `${resStr} ${unit}`
}
