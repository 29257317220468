'use client'
import type { FC } from 'react'
import type { StackProps, TextProps } from '@chakra-ui/react'
import { Stack } from '@chakra-ui/react'
import { commaSeparator } from 'shared-utils'
import { Currency } from 'ecosystem'
import { useOverridesContext } from '../lib/overrides/hooks'
import { useUIGlobalsContext } from '../lib/ui-globals/hooks'
import { overrideText } from '../lib/overrides'
import GrandText from './GrandText'

interface IPriceProps extends StackProps {
  price: number
  originalPrice: number
  originalPriceProps?: TextProps
  priceProps?: TextProps
  orientation?: 'horizontal' | 'vertical'
  prefix?: string
}

export interface PriceOverrides {
  priceCurrency?: string
}

export const Price: FC<IPriceProps> = ({
  price,
  originalPrice,
  originalPriceProps,
  priceProps,
  orientation = 'horizontal',
  prefix,
  ...props
}) => {
  const hasDiscount = price < originalPrice
  const overrides = useOverridesContext<keyof PriceOverrides>()
  const { priceMaximumFractionDigits } = useUIGlobalsContext()

  return (
    <Stack
      direction={orientation === 'horizontal' ? 'row' : 'column-reverse'}
      flex={1}
      spacing={orientation === 'horizontal' ? 2 : 0}
      w="full"
      {...props}>
      {hasDiscount ? (
        <GrandText
          color="status.error"
          flex-grow={1}
          fontSize={{
            base: 'sm'
          }}
          fontWeight="medium"
          width="auto"
          {...priceProps}>
          {commaSeparator(price, priceMaximumFractionDigits)}{' '}
          {overrideText(Currency.SEK, overrides?.priceCurrency)}
        </GrandText>
      ) : null}

      <GrandText
        color={hasDiscount ? 'text.mild' : 'text.default'}
        flex-grow={1}
        fontSize={{
          base: 'sm',
          md: hasDiscount ? 'xs' : 'sm'
        }}
        fontWeight="medium"
        textDecoration={hasDiscount ? 'line-through' : 'none'}
        width="auto"
        {...originalPriceProps}>
        {prefix}{' '}
        {price
          ? commaSeparator(hasDiscount ? originalPrice : price, priceMaximumFractionDigits)
          : 0}{' '}
        {overrideText(Currency.SEK, overrides?.priceCurrency)}
      </GrandText>
    </Stack>
  )
}
