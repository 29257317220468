import { useEffect, useState } from 'react'

/**
 * A custom React hook that determines if the component is mounted on the client side.
 *
 * This can help prevent hydration errors in Next.js by ensuring certain logic runs
 * only after the component has mounted.
 *
 * @returns boolean: `true` if running on the client side, otherwise `false` (before mount).
 * @see https://nextjs.org/docs/messages/react-hydration-error
 */
export const useIsClient = () => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return isClient
}
