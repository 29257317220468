import type { FC, ReactElement } from 'react'
import type { PopoverContentProps, PopoverProps } from '@chakra-ui/react'
import {
  Portal,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger
} from '@chakra-ui/react'
import { motion } from 'framer-motion'

interface IGrandPopover extends PopoverProps {
  title: string
  target: ReactElement
  contentProps?: PopoverContentProps
  children: any
  isContentInPortal?: boolean
  borderRadius?: string | number
}

const MotionPopoverContent = motion(PopoverContent)

const GrandPopover: FC<IGrandPopover> = ({
  title,
  target,
  children,
  contentProps,
  isContentInPortal,
  borderRadius,
  isOpen,
  ...props
}) => {
  const popoverContentProps = {
    borderRadius: borderRadius || contentProps?.borderRadius || 'md',
    outlineColor: 'transparent',
    ...contentProps
  }

  const animationVariants = {
    hidden: { opacity: 0, scale: 0.5, y: -50 },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 500,
        damping: 20,
        mass: 1,
        duration: 0.5
      }
    }
  }

  const popoverContent = (
    <MotionPopoverContent
      color="text.default"
      initial="hidden"
      animate={isOpen ? 'visible' : 'hidden'}
      variants={animationVariants}
      {...popoverContentProps}>
      {title ? <PopoverHeader fontWeight="semibold">{title}</PopoverHeader> : null}
      <PopoverArrow />
      {title ? <PopoverCloseButton _focus={{ outline: 'none' }} aria-label="close" /> : null}
      <PopoverBody>{children}</PopoverBody>
    </MotionPopoverContent>
  )

  return (
    <Popover
      closeOnBlur
      isLazy
      placement="top-end"
      returnFocusOnClose={false}
      variant="responsive"
      isOpen={isOpen}
      {...props}>
      <PopoverTrigger>{target}</PopoverTrigger>
      {isContentInPortal ? <Portal>{popoverContent}</Portal> : popoverContent}
    </Popover>
  )
}

export default GrandPopover
