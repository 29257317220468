import { Currency } from 'ecosystem'
import { fileSizeValue } from './utils/fileSize'

export const DEFAULT_COUNTRY = 'SE'
export const DEFAULT_CURRENCY = Currency.SEK
export const IS_PRODUCTION = process.env.NODE_ENV === 'production'
export const VAT_RATES = {
  SE: 25,
  NO: 25,
  FI: 25.5
}
/** @see https://vercel.com/docs/errors/FUNCTION_PAYLOAD_TOO_LARGE */
export const VERCEL_UPLOAD_MAX_SIZE = fileSizeValue(4.5, 'mb')
